<template>
    <div class="content">
          <!-- <img class="logo" src="../assets/image/321.png" alt=""> -->
          <div class="login_content">
              <h2>扁鹊问诊管理</h2>
              <div class="content_login">
                  <h2>用户信息</h2>
                  <div class="content_input-right-input">
                    <el-form ref="form" :model="form" label-width="40px" label-position="left">
                        <el-form-item label="姓名">
                            <el-input v-model="form.name"  placeholder="请输入姓名"></el-input>
                        </el-form-item>
                        <el-form-item label="年龄">
                            <el-input v-model="form.age" placeholder="请输入年龄"></el-input>
                        </el-form-item>
                        <el-form-item label="性别">
                            <el-radio v-model="form.sex" label="man">男</el-radio>
                            <el-radio v-model="form.sex" label="girl">女</el-radio>
                        </el-form-item>
                        <el-form-item label="地址">
                            <el-input v-model="form.address" placeholder="请输入地址"></el-input>
                        </el-form-item>
                    </el-form>
                    <div class="dis">
                        <el-button style="width:40%;"  @click="Return">返 回</el-button>
                        <el-button style="width:40%;background: #f8d347; color: #000;height: 60px;"  @click="modify">确 定 </el-button>
                    </div>
                  </div>
              </div>
          </div>
      </div>
  </template>
  <script>
  export default {
       data() {
          return {
            form:{
                name:'',
                age:'',
                sex:'',
                address:''
            },
          }
        },
        methods:{
            modify(){
                this.$api.article.getrole('role').then(res=>{
                    let role = res.data.result.find(element => {
                        return element.role_name == '病人'
                    });
                       this.$api.article.usercreated(
                            {
                                user:{
                                    name:this.form.name,
                                    sex:this.form.sex,
                                    age:this.form.age,
                                    identity:'',
                                    address:this.form.address
                                }
                            }
                        ).then(res => {
                            localStorage.setItem("user",JSON.stringify(res.data.result));
                            let id = res.data.result.id
                            this.$api.article.userrole(
                                {
                                    role:{
                                        role_id:role.id
                                    }
                                }
                            ).then(res => {
                                localStorage.setItem("userrole_id",res.data.result.id);
                                this.$router.push('/')
                            })
                        })
                       

                        
                })
             
            },
            getuser(state){
               
            },
            Return(){
                this.$router.back(-1);
            }
        },
        created(){
        }
  }
  </script>
  <style lang="less" scoped>
  .dis{
    display: flex;
    justify-content: space-between;
  }
  .logo{
      width: 200px;
      height: 62px;
      position: absolute;
      left: 2%;
      top: 2%;
  }
  .content{
      width: 100%;
      // min-width: 1600px;
      min-height: 789px;
      height: 100%;
      background-image: url('../assets/image/logonbg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
  }
  .login_content{
      width: 80%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
  }
  .login_content>h2{
      width: 200%;
      position: relative;
      left: -50%;
      color: #fff;
      text-align: center;
      padding: 20px;
      font-weight: 600;
      font-size: 1.8em;
      margin-top: 40%;
      letter-spacing:8px;
  }
  .footer_{
      width: 200%;
      position: fixed;
      color: #fff;
      bottom: 20px;
  }
  .content_login{
      background: #fff;
      border-radius: 6px;
      padding: 20px;
  }
  .content_login>h2{
      padding: 20px;
      padding-left: 0;
      color: #000;
  }
  .content_input_logon{
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      margin-top: 8%;
      padding-bottom: 10%;
  }
  </style>